.helps_container {
    width: 100%;

    >h2 {
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        font-weight: 400;
    }

    >.helps_blocks_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;


        >.helps_block {
            width: 31%;
            background-color: white;
            border-radius: 27px;
            box-shadow: 0px 4px 22px rgb(0 0 0 / 17%);
            min-height: 350px;
            margin-top: 20px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            >img {
                margin: 0 auto;

            }

            >p {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 10px;
                color: #1E272E;
                text-align: center;

            }

            >span {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: #828282;
            }
        }
    }
}