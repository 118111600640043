.ourTeam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 50px;
}

.ourTeam > h2 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 400;
  color: #1e272e;
}

.ourTeam > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #828282;
  text-align: center;
}

.teamMembers {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.eachTeamMember {
  border-radius: 27px;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 17%);
  width: calc(25% - 40px);
  padding: 40px 0 32px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.eachTeamMember:hover {
  box-shadow: 0px 2px 14px rgb(0 0 0 / 15%);
}

.teamNames {
  text-align: center;
}

.eachTeamImg {
  background: url("../../../../public/img.png") no-repeat center / cover;
  border-radius: 50%;
  width: 140px;
  height: 140px;
}

.eachTeamImg > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
