.banner {
  display: flex;
  align-items: center;
  justify-content: center;

  > .banner_container {
    max-width: 1600px;
    padding: 10px 43px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    > .welcome_block {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      > h1 {
        font-size: 34px;
        line-height: 50px;
        font-weight: bold;
        font-family: "Raleway", sans-serif;
        font-variant: lining-nums;
      }

      > p {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #01070b;
      }

      > .welcoming {
        display: flex;
        gap: 50px;

        > .icons_block {
          display: flex;
          gap: 20px;
        }

        > div {
          > span {
            color: #333333;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
          }

          > p {
            color: #828282;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
      > .btn_divs {
        display: flex;
        gap: 20px;

        > button {
          background-color: #0092e4;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          padding: 13px 28px;
          color: white;
          border: 1px solid #fff;
          border-radius: 30px;
          cursor: pointer;
          width: fit-content;

          &:hover {
            text-decoration: underline;
          }
        }

        > .transparent_btn {
          font-family: "Lato", sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          padding: 13px 28px;
          border: 1px solid #0092e4;
          border-radius: 30px;
          box-sizing: border-box;
          color: #333333;
          background: transparent;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    > .pictures_block {
      background: url("../../../public/pattern_orange.png") no-repeat center /
        cover;
      width: 583px;
      height: 480px;
      position: relative;
      > .front_img {
        position: absolute;
        border-radius: 50% 0;
        width: 500px;
        height: 400px;
        right: 4px;
        bottom: 63px;
      }
    }
  }
}
