.joinImg {
  position: absolute;
  right: 0;
  top: -27%;
  width: 500px;
  height: 600px;
}

.joinText {
    left: 10% !important;
}

.joinText > p {
  font-family: "Raleway", sans-serif;
  font-variant: lining-nums;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
}

.joinText > p > span {
  font-weight: 300 !important;
}
