.navbar_container {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;

    >.navbar_center_block {
        max-width: 1600px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 43px;
        width: 100%;

        >button {
            background-color: #0092e4;
            display: inline-block;
            position: relative;
            text-align: center;
            text-decoration: none;
            font-family: "Lato", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            padding: 13px 28px;
            color: white;
            border: 1px solid #fff;
            border-radius: 30px;
            box-sizing: border-box;
            overflow: hidden;
            outline: 0;
            cursor: pointer;
            user-select: none;

            &:hover {
                text-decoration: underline;
            }
        }

        >.logo_block {
            display: flex;
            align-items: center;
            padding: 14px 0;

            >img {
                width: 128px;
                cursor: pointer;

                &:hover {
                    opacity: 0.5;
                }
            }

            >.navbar_list {
                width: 450px;
                margin-left: 10px;


                >ul {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;

                    >li {
                        list-style-type: none;

                        >a {

                            text-decoration: none;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 20px;
                            padding: 3px;
                            color: #828282;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}