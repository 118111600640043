.mobileProject_container {
    width: 100%;
    position: relative;
    margin-top: 40px;
    z-index: 1;

    ::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('https://sedovcompany.ru/wa-data/public/site/themes/default/img/patterns/pattern_seoresults.png');
        background-size: 540px;
        background-position-x: calc(50% + 690px);
        background-position-y: 0;
        background-repeat: no-repeat;

    }

    >h2 {
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;
    }

    >.mobileProject_block {
        width: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        flex-wrap: wrap;


        >.mob_mini_block {
            width: 46.8%;
            background: white;
            padding: 30px;
            border: 1px solid silver;
            border-radius: 20px;
            min-height: 420px;
            margin-top: 20px;
            z-index: 9;

            >img {
                width: 100%;
                height: 300px;
                
            }
        }
    }
}