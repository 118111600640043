.trustUs {
  background: radial-gradient(#0092e4, #004870);
  width: 100%;
  height: 412px;
  margin: 100px 0;
}

.bluePattern1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.trustImg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 530px;
  height: 100%;
}

.trustText {
  position: absolute;
  top: 100px;
  left: 20%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.trustText > p {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  color: #ffffff;
}

.trustText > button {
  background: #ff6a38;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 13px 28px;
  border: 1px solid #fff;
  border-radius: 30px;
  width: fit-content;
}

.trustText > button:hover {
  text-decoration: underline;
}

.maxTrust {
  max-width: 1600px;
  width: 100%;
  height: 412px;
  position: relative;
  margin: 0 auto;
}
