.mainFooter {
  margin: 50px 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 30px;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.footerApps {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footerColumn > h3 {
  color: #ff6501;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.footerColumn > p {
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.footerColumn > p > a {
  color: #ff6501;
  line-height: 28px;
}

.footerHr {
  width: 100%;
  height: 2px;
  background: #e0e0e0;
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.footerBottom > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #828282;
}

.footerNavigation {
  display: flex;
  gap: 20px;
}

.footerNavigation > a {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #828282;
}
