.websites {
  margin-top: 100px;
}

.websites > h2 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}

.websiteContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 60px;
  margin-top: 30px;
}

.eachWebsiteContainer {
  padding: 30px;
  border: 1px solid silver;
  border-radius: 20px;
  width: 46.8%;
}

.eachWebsiteContainer > a {
  color: #ee5a24;
  text-decoration: none;
}

.eachWebsiteContainer > a > h6 {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.eachWebsiteContainer > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  margin-top: 20px;
}
